import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column', // Stack image and text vertically on very small screens
    alignItems: 'center', // Center alignment for image and text on small screens
    boxShadow: 'none',
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row', // Default to horizontal layout on larger screens
      alignItems: 'center',
      maxWidth: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '600px'
    }
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column', // Vertical layout for image and text
    justifyContent: 'start',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row' // Default to horizontal layout on larger screens
    }
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 140,
    width: '100%',
    maxWidth: 200,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none', // Hide the image wrapper on small screens
      marginRight: '0' // Remove margin for vertical stack layout
    }
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    borderRadius: '8px'
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      order: 2 // Move the title below the image
    }
  },
  arrowIcon: {
    color: theme.palette.text.secondary,
    marginLeft: 'auto'
  },
  titleResponsive: {
    textAlign: 'center',
    fontWeight: 700,
    marginBottom: theme.spacing(4), // This adds space below the title
    marginTop: theme.spacing(4), // This adds space above the title
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '52px'
    }
  }
}));

const ItemCard = ({ item }) => {
  const classes = useStyles();
  const { url, image, title } = item;

  return (
    <Card className={classes.card}>
      {/* Use Gatsby `Link` component here */}
      <CardActionArea component={Link} to={url} className={classes.actionArea} rel="noopener">
        <div className={classes.imageWrapper}>
          <img src={image} alt={title} className={classes.image} />
        </div>
        <CardContent>
          <Typography className={classes.title} variant="subtitle1" component="p">
            {title}
          </Typography>
        </CardContent>
        <ArrowForwardIosIcon className={classes.arrowIcon} />
      </CardActionArea>
    </Card>
  );
};

ItemCard.propTypes = {
  item: PropTypes.shape({
    url: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

const splitTitle = title => {
  const [beforeColon, afterColon] = title.split(':');
  return (
    <>
      {beforeColon}:
      <br />
      {afterColon}
    </>
  );
};

const MinimalVideoList = ({ items, title, bottom_shape_divider }) => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Typography variant="h2" className={classes.titleResponsive}>
          {splitTitle(title)}
        </Typography>
        <Grid container spacing={1} justify="center">
          {items.map(item => (
            <Grid item xs={12} key={item.id}>
              <ItemCard item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

MinimalVideoList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(ItemCard.propTypes.item)).isRequired,
  bottom_shape_divider: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

MinimalVideoList.defaultProps = {
  bottom_shape_divider: null
};

export default MinimalVideoList;
